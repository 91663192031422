<template>
  <div class="container-box">
    <div class="report-box">
      <div>
        <span class="report-label">患者姓名：</span>
        <span class="report-value">{{ CurrentJZPerson.name }}</span>
      </div>
      <div>
        <span class="report-label">证件号：</span>
        <span class="report-value">{{ CurrentJZPerson.idNumber }}</span>
      </div>
      <div>
        <span class="report-label">报告时间：</span>
        <span class="report-value">{{
          moment($route.query.reportTime).format("YYYY-MM-DD")
        }}</span>
      </div>
    </div>
    <baseTable
      :option="option"
      :tableData="$store.state.laboratoryDetails"
    ></baseTable>
  </div>
</template>

<script>
import moment from "moment";
import baseTable from "../../components/common/baseTable.vue";
export default {
  name: "report",
  components: { baseTable },
  data() {
    return {
      moment,
      CurrentJZPerson: {},
      option: {
        column: [
          {
            label: "项目名称",
            tableDataprop: "itemName",
            width: 200,
          },
          {
            label: "结果",
            tableDataprop: "result",
            width: 80,
          },
          {
            label: "单位",
            tableDataprop: "resultUnit",
            width: 80,
          },
          {
            label: "参考范围",
            tableDataprop: "scope",
            width: 100,
          },
        ],
      },
    };
  },
  mounted() {
    this.CurrentJZPerson = JSON.parse(
      sessionStorage.getItem("CurrentJZPerson")
    );
  },
};
</script>

<style scoped>
.container-box {
  padding: 5px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: #fff;
}
.report-box {
  width: 10rem;
  margin: 0 auto;
  text-align: left;
  padding: 15px;
  font-size: 0.4rem;
}
</style>
