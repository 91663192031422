<template>
  <div class="vant-table">
    <table cellspacing="0" :style="bgcolor" style="width:100%" class="table">
      <tr>
        <th class="th" v-for="(item, index) in option.column" :key="index">{{ item.label }}</th>
      </tr>
      <tr v-for="(item, index) in tableData" :key="index" class="list-tr">
        <td v-for="(context, i) in option.column" :key="i" :width="context.width">{{ item[context.tableDataprop] }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name:'TableVant',
  props:{
    bgcolor:{
      type: Object,
      default: ()=>{}
    },
    tableData:{
      type: Array,
      default: []
    },
    option:{
      type: Object,
      default: {}
    }
  },
  created() {}
};
</script>

<style  scoped>

.table {
  border-radius: .185185rem;
  background-color: #fff;
  font-size: 0.35rem;
}
.th {
  height: 1.074074rem;
  line-height: 1.074074rem;
  background-color: #D0E6FD;
  text-align: center;
  color: #3D69AB;
  border: 1px solid #919191;
}
.list-tr {
  height: 1.074074rem;
  line-height: 1.074074rem;
}
.list-tr:nth-child(2n) {
  /*background-color: #33333b;*/
}
td {
  text-align: center;
  border: 1px solid #919191;
}
</style>
